import { t, T } from "@kanpla/system";
import { Button, Input } from "@kanpla/ui";
import { Heading } from "../components/Layout";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InputWithMessage } from "../components/InputWithMessage";

const FORM_SCHEMA = z.object({
  username: z.string(),
});

type UsernameFormType = z.infer<typeof FORM_SCHEMA>;

interface Props {
  onSubmit: (values: UsernameFormType) => void;
  usernameValue?: string;
}
export const Username = ({ onSubmit, usernameValue }: Props) => {
  const { handleSubmit, register, formState, watch } =
    useForm<UsernameFormType>({
      resolver: zodResolver(FORM_SCHEMA),
      values: {
        username: usernameValue || "",
      },
    });

  const { isDirty, isValidating, isSubmitting } = formState;
  const usernameError = formState.errors.username;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <Heading>
        <T _str="Your Name" />
      </Heading>
      <InputWithMessage
        input={
          <Input
            placeholder={t("ie. Jane Doe")}
            required
            error={!!formState.errors.username}
            value={watch("username")} // value is necessary for mobile input label
            label={t("Name")}
            {...register("username")}
          />
        }
        isError={!!usernameError}
        message={usernameError?.message}
      />
      <Button
        htmlType="submit"
        type="primary"
        shape="soft"
        disabled={!isDirty}
        loading={isValidating || isSubmitting}
      >
        <T _str="Continue" />
      </Button>
    </form>
  );
};
