import { faFaceMonocle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@kanpla/system";

export const NoLocationsFound = () => (
  <div className="flex flex-col items-center gap-4 my-8 text-text-secondary">
    <FontAwesomeIcon icon={faFaceMonocle} size="2xl" className="opacity-80" />
    <p>
      <T _str="No locations found" />
    </p>
  </div>
);
