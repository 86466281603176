import { useState } from "react";

export function useStack<T>(initial: T[]) {
  const [stack, setStack] = useState<T[]>(initial);
  const push = (value: T) => setStack((prev) => [...prev, value]);
  // If we want to reuse it, we might want to return the popped value
  const pop = () => setStack((prev) => prev.slice(0, -1));
  const replace = (value: T) =>
    setStack((prev) => [...prev.slice(0, -1), value]);
  const top = stack.at(-1);
  return { top, push, pop, replace, stack };
}
