import { callInternalApi, humanSortBy } from "@kanpla/system";
import { useQuery } from "@tanstack/react-query";
import {
  GetSchoolTreeArgs,
  GetSchoolTreeResponse,
} from "apps/frontend/pages/api/internal/school/get-available-schools-for-child-creation";

export const useSchoolsForChildCreationQuery = (
  email: string | undefined,
  enabled?: boolean
) => {
  const query = useQuery({
    queryKey: ["school/get-available-schools-for-child-creation", email],
    queryFn: async () => {
      const schools = await callInternalApi<
        GetSchoolTreeArgs,
        GetSchoolTreeResponse
      >("school/get-available-schools-for-child-creation", {
        email,
      });
      if (!schools) return [];

      return humanSortBy(schools, (school) => school.name);
    },
    enabled,
  });
  return query;
};
