import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import React from "react";
import { Heading } from "../components/Layout";

interface Props {
  onIncorrect: () => void;
  onCorrect: () => void;
  selectedLocation: string;
}
export const SchoolConfirmation = ({
  selectedLocation,
  onIncorrect,
  onCorrect,
}: Props) => {
  return (
    <div>
      <div className="w-[84px] h-[84px] flex items-center justify-center bg-primary-main rounded-full mx-auto mb-[75px]">
        <FontAwesomeIcon icon={faCheck} className="text-white" size="2xl" />
      </div>

      <div className="text-center mb-14">
        <span className="text-base">
          <T
            _str="You're connected to {location}"
            location={
              <Heading>
                <T _str={selectedLocation} />
              </Heading>
            }
          />
        </span>
      </div>

      <div className="flex flex-col items-stretch gap-7">
        <Button shape="soft" type="primary" onClick={onCorrect}>
          <T _str="Yes, correct" />
        </Button>

        <Button shape="soft" type="secondary" onClick={onIncorrect}>
          <T _str="Incorrect" />
        </Button>
      </div>
    </div>
  );
};
