import { T, callInternalApi, humanSortBy, useT } from "@kanpla/system";
import { ChildSelector, School, Selector } from "@kanpla/types";
import { Input, Spinner } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import {
  GetAvailableSelectorsProps,
  GetAvailableSelectorsResponse,
} from "apps/frontend/pages/api/internal/signup/get-available-selectors";
import { capitalize, deburr, orderBy } from "lodash";
import React from "react";
import { Heading } from "../components/Layout";
import ListSelect from "../components/ListSelect";
import { Ok } from "../util/Result";

function shouldDisableOption(
  option: Selector["options"][number],
  selectedSelectors: ChildSelector
) {
  return Object.entries(option.disabled ?? {}).some(([key, value]) =>
    value.includes(selectedSelectors[key])
  );
}
/** Returns the next selector to be selected (or null if all selectors are selected) */
function nextSelectorToSelect(
  selectedSelectors: ChildSelector,
  allSelectors: Selector[]
): Selector | null {
  for (const selector of allSelectors) {
    const isPicked = Boolean(selectedSelectors[selector.name]);
    if (!isPicked) {
      const options = selector.options.filter(
        (option) => !shouldDisableOption(option, selectedSelectors)
      );
      if (options.length) {
        return {
          ...selector,
          options,
        };
      }
    }
  }
  // All selectors selected
  return null;
}

type Props = {
  /** ID of the school for which we will offer selectors */
  schoolId: School["id"];
  /** Email of the user (optional) */
  email?: string;
  /** The selectors that have already been selected */
  selectedSelectors: ChildSelector;
  /** Triggered whenever a Selector is selected */
  onSelectorSelect: (selector: ChildSelector) => void;
  /** Triggered when all Selectors have been selected */
  onAllSelectorsSelected: (selector: ChildSelector) => void;
  /** Triggered where there are no Selectors for the target school */
  onSkip: () => void;
};

export function Selectors({
  schoolId,
  email,
  selectedSelectors,
  onSelectorSelect,
  onAllSelectorsSelected,
  onSkip,
}: Props) {
  const query = useQuery({
    queryKey: ["signup/get-available-selectors", schoolId, email],
    queryFn: async () => {
      const result = await callInternalApi<
        GetAvailableSelectorsProps,
        GetAvailableSelectorsResponse
      >("signup/get-available-selectors", { schoolId, email });

      if (!result || result.isErr) return result;
      // Sort selectors by position
      return Ok(orderBy(result.data, (selector) => selector.position));
    },
  });

  if (query.isLoading) return <Spinner />;

  // Handle errors
  if (!query.data) return <T _str="Internal error" />;
  const result = query.data;
  if (result.isErr) {
    if (result.error === "SCHOOL_NOT_FOUND") {
      return <T _str="School not found" />;
    }
    return <T _str="Internal error" />;
  }

  const selectors = result.data;
  const exploredSelector = nextSelectorToSelect(selectedSelectors, selectors);
  if (exploredSelector === null) {
    onSkip();
    return <Spinner />;
  }

  const handleItemSelect = (name: string, optionName: string) => {
    const newChildSelector = {
      ...selectedSelectors,
      [name]: optionName,
    };
    const nextExploredSelector = nextSelectorToSelect(
      newChildSelector,
      selectors
    );
    if (nextExploredSelector === null) {
      onAllSelectorsSelected(newChildSelector);
      return <Spinner />;
    }
    onSelectorSelect(newChildSelector);
  };

  const sortedOptions = humanSortBy(exploredSelector.options, (s) => s.name);
  return (
    <>
      <Heading>
        <T _str="Choose {selectorName}" selectorName={exploredSelector.name} />
      </Heading>
      <ListWithSearch
        key={exploredSelector.name}
        nodes={sortedOptions}
        onChange={(selectorOptionName) =>
          handleItemSelect(exploredSelector.name, selectorOptionName)
        }
      />
    </>
  );
}

const TRIGGER = 10;
function isMatching(node: { name: string }, search: string) {
  const formatString = (str: string) => deburr(str.toLowerCase());
  return formatString(node.name).includes(formatString(search));
}

const ListWithSearch = ({
  nodes,
  onChange,
}: {
  nodes: { name: string }[];
  onChange: (value: string) => void;
}) => {
  const t = useT();
  const [search, setSearch] = React.useState("");
  const ref = React.useRef<HTMLInputElement>(null);

  const show = nodes.length >= TRIGGER;
  const filteredNodes = nodes.filter((node) => isMatching(node, search.trim()));

  return (
    <>
      {show && (
        <Input.Search
          ref={ref}
          className="mb-3"
          placeholder={t("Search by name...")}
          value={search}
          onChange={setSearch}
        />
      )}
      <ListSelect>
        {filteredNodes.map((node) => (
          <ListSelect.Item
            key={node.name}
            label={capitalize(node.name)}
            onClick={() => onChange(node.name)}
          />
        ))}
      </ListSelect>
    </>
  );
};
