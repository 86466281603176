import { callInternalApi, getErrorMessage, T, useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";

import { zodResolver } from "@hookform/resolvers/zod";
import { School, Scope } from "@kanpla/types";
import { useQueryClient } from "@tanstack/react-query";
import {
  GetSchoolOptions,
  GetSchoolResponse,
} from "apps/frontend/pages/api/internal/signup/school";
import { pick } from "lodash";
import dynamic from "next/dynamic";
import { Controller, useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { z } from "zod";
import { AppContext } from "../../contextProvider";
import { InputWithMessage } from "../components/InputWithMessage";
import { Heading } from "../components/Layout";
import { Err, Ok, Result } from "../util/Result";

const ReactCodeInput = dynamic(() =>
  import("react-code-input").catch(() => {
    return () => (
      <p>
        <T _str="Failed to load" />
      </p>
    );
  })
);

type CanteenIdFormType = { canteenIdCode: string };
type CanteenIdFormReturnType = {
  school: Pick<School, "id" | "name">;
  scope?: Scope;
};
interface Props {
  onSubmit: (data: CanteenIdFormReturnType) => void;
}
export const CanteenId = ({ onSubmit: onSuccess }: Props) => {
  const t = useT();

  const { handleSubmit, formState, setError, control } =
    useForm<CanteenIdFormType>({
      resolver: zodResolver(
        z.object({
          canteenIdCode: z
            .string({ required_error: t("Please enter the code") })
            .min(4, t("The code has to be 4 digits long")),
        })
      ),
    });

  const validateCanteenId = useCanteenIdValidation();

  const { supplier } = useContainer(AppContext);

  const onSubmit = async ({ canteenIdCode }: CanteenIdFormType) => {
    const partnerId = supplier?.partnerId;
    const response = await validateCanteenId(canteenIdCode, partnerId);
    if (response.isErr) {
      setError("canteenIdCode", response.error);
      return;
    }
    onSuccess(response.data);
  };

  const { isDirty, isValidating, isSubmitting } = formState;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <Heading>
        <T _str="Enter Canteen ID" />
      </Heading>
      <p>
        <T _str="Find it inside the canteen or by asking canteen staff or your employer." />
      </p>
      <InputWithMessage
        input={
          <Controller<CanteenIdFormType>
            name="canteenIdCode"
            control={control}
            // pick out ref to avoid bug on keyboard submission
            render={({ field: { ref, ...field } }) => (
              // @ts-expect-error This is a package issue
              <ReactCodeInput
                type="text"
                className="react-code-input-wrapper"
                fields={4}
                autoFocus
                inputMode="latin"
                isValid={!formState.errors.canteenIdCode}
                {...field}
              />
            )}
          />
        }
        isError={!!formState.errors.canteenIdCode}
        message={formState.errors.canteenIdCode?.message}
      />

      <Button
        htmlType="submit"
        type="primary"
        shape="soft"
        disabled={!isDirty}
        loading={isValidating || isSubmitting}
      >
        <T _str="Continue" />
      </Button>
    </form>
  );
};

type CanteenIdErrorType = "INVALID" | "INTERNAL";
type CanteendIdOk = CanteenIdFormReturnType;
type CanteenIdError = { type: CanteenIdErrorType; message?: string };

function useCanteenIdValidation() {
  const t = useT();
  const client = useQueryClient();

  const validateCanteenId = async (
    code: string,
    partnerId?: string
  ): Promise<Result<CanteendIdOk, CanteenIdError>> => {
    let response: GetSchoolResponse | undefined;
    try {
      response = await client.fetchQuery({
        queryKey: ["signup/school", code],
        queryFn: () => {
          return callInternalApi<GetSchoolOptions, GetSchoolResponse>(
            "signup/school",
            {
              code,
              partnerId,
            }
          );
        },
      });
    } catch (e) {
      console.error(getErrorMessage(e));
      return Err({
        type: "INTERNAL",
        message: t("No response from the server, try again."),
      });
    }
    if (!response)
      return Err({
        type: "INTERNAL",
        message: t("No response from the server, try again."),
      });
    if (!response.school)
      return Err({ type: "INVALID", message: t("Wrong canteen ID") });
    return Ok({
      school: pick(response.school, ["id", "name"]),
      scope: response.scope,
    });
  };
  return validateCanteenId;
}
