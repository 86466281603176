import React from "react";
import { ListSelectItem } from "./ListSelectItem";

/**
 * Wrapper for a list of selectable items.
 *
 * @example
 * ```tsx
 * <ListSelect>
 *   {items.map(item => <ListSelect.Item ... />)}
 * </ListSelect>
 * ```
 */
const ListSelect = ({ children }: { children: React.ReactNode }) => {
  return <ul className="divide-y divide-divider-main">{children}</ul>;
};

ListSelect.Item = ListSelectItem;

export default ListSelect;
