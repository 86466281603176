import { TreeNode } from "@kanpla/types";
import { SchoolTreeNode } from "apps/frontend/pages/api/internal/school/get-available-schools-for-child-creation";

// HELPERS
function hasParent(node: TreeNode, parentId: string): boolean {
  return node.parents[parentId];
}

function hasNoParent(node: TreeNode): boolean {
  return Object.keys(node.parents).length === 0;
}

export function pickNodesToDisplay(
  allNodes: SchoolTreeNode[],
  groupId: string | undefined
) {
  // Group is selected, show only its children
  if (groupId) return allNodes.filter((node) => hasParent(node, groupId));

  // No group selected, show only root nodes (those without parents)
  const rootNodes = allNodes.filter((node) => hasNoParent(node));

  // If there are no root nodes, show all nodes
  if (rootNodes.length === 0) return allNodes;
  return rootNodes;
}
