import classNames from "classnames";

type Props = {
  input: React.ReactNode;
  message?: React.ReactNode;
  isError: boolean;
  isValid?: boolean;
  className?: string;
};
export const InputWithMessage = ({
  input,
  message,
  isError,
  isValid,
  className,
}: Props) => {
  return (
    <div className={classNames("flex flex-col gap-1", className)}>
      {input}
      {message ? (
        <p
          className={classNames("text-sm", {
            "text-danger-dark": isError,
            "text-text-secondary": !isError && !isValid,
            "text-success-dark": !isError && isValid,
          })}
        >
          {message}
        </p>
      ) : null}
    </div>
  );
};
