import { useNativeKeyboardOffset } from "@kanpla/system";
import { SchoolTreeNode } from "apps/frontend/pages/api/internal/school/get-available-schools-for-child-creation";
import { deburr } from "lodash";
import React from "react";

function isMatching(node: SchoolTreeNode, search: string) {
  const formatString = (str: string) => deburr(str.toLowerCase());
  return (
    formatString(node.name).includes(formatString(search)) ||
    node.canteenId === search ||
    node.id === search
  );
}

const TRIGGER = 10;
export const useSearchInput = (nodes: SchoolTreeNode[]) => {
  const [search, setSearch] = React.useState("");
  const ref = React.useRef<HTMLInputElement>(null);

  const show = nodes.length >= TRIGGER;
  const filteredNodes = nodes.filter((node) => isMatching(node, search.trim()));

  React.useEffect(() => {
    if (show) {
      ref.current?.focus();
    }
  }, [show]);

  useNativeKeyboardOffset("HUGE", show);

  return { show, ref, nodes: filteredNodes, setSearch, search };
};
