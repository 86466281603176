import { T } from "@kanpla/system";
import { Button } from "@kanpla/ui";

type Props = {
  navigate: () => void;
  children: React.ReactNode;
};
export const WithCanteenId = ({ navigate, children }: Props) => {
  return (
    <div className="flex flex-col">
      {children}
      <p className="mt-6 mb-3">
        <T _str="or" />
      </p>
      <CanteenIdButton navigate={navigate}>
        <T
          _str="Signup with {bold}"
          bold={
            <span className="font-semibold">
              <T _str="Canteen ID" />
            </span>
          }
        />
      </CanteenIdButton>
    </div>
  );
};

export const CanteenIdButton = ({
  navigate,
  children,
}: {
  navigate: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Button
      className="self-start *:inline"
      shape="solid"
      size="medium"
      dataCy="btn-signup-login-canteen-id"
      onClick={navigate}
    >
      {children}
    </Button>
  );
};
