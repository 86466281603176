import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const ListSelectItem = ({ label, onClick, disabled }: Props) => {
  return (
    <li>
      <button
        disabled={disabled}
        className={classNames(
          "w-full flex items-center justify-between px-4 sm:px-6 lg:px-8 py-4 hover:bg-background-secondary text-start",
          disabled && "text-text-disabled cursor-not-allowed"
        )}
        onClick={onClick}
      >
        {label}
        {!disabled && <FontAwesomeIcon icon={faChevronRight} />}
      </button>
    </li>
  );
};
