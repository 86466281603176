/**
 * A Result type that represents the result of an operation that can either succeed or fail.
 *
 * @typeParam T The type of the data that is returned in case of success.
 * @typeParam E The type of the error that is returned in case of failure.
 *
 * @example
 * ```ts
 * const result = someOperationReturningResult();
 * if (result.isErr) {
 *   return handleError(result.error); // result.error is of type E (thanks to `!result.isErr` check)
 * }
 * handleSuccess(result.data); // result.data is defined and of type T (thanks to early return in case of error)
 * ```
 *
 * Inspired by Rust's `Result` type.
 */
export type Result<T, E> =
  | {
      isErr: false;
      data: T;
    }
  | {
      isErr: true;
      error: E;
    };

/** Creates a successful `Result` with the given data. */
export function Ok<T, E>(data: T): Result<T, E>;
/** Creates a successful `Result` with no data. */
export function Ok<E>(): Result<undefined, E>;
/** Creates a successful `Result` */
export function Ok<T, E>(data?: T): Result<T | undefined, E> {
  return { isErr: false as const, data };
}
/** Creates a failed `Result` with the given error. */
export function Err<E>(error: E) {
  return { isErr: true as const, error };
}
