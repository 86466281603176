import classNames from "classnames";
export default function Layout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        // Used `dvh` instead of `vh` to avoid the iOS Safari bug
        "flex flex-col h-[100dvh] max-w-screen-xl mx-auto p-8 gap-8 sm:gap-20",
        className
      )}
    >
      {children}
    </div>
  );
}

const Inner = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={classNames("self-center w-full max-w-sm", className)}>
    {children}
  </div>
);

export const Heading = ({ children }: { children: React.ReactNode }) => (
  <h1 className="title-main text-balance break-words">{children}</h1>
);

Layout.Inner = Inner;
