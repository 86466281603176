import { School } from "@kanpla/models";
import { T, useT } from "@kanpla/system";
import { Input, Spinner } from "@kanpla/ui";
import React from "react";
import { Heading } from "../../components/Layout";
import ListSelect from "../../components/ListSelect";
import { NoLocationsFound } from "./NoLocationsFound";
import { pickNodesToDisplay } from "./pickNodesToDisplay";
import { useSchoolsForChildCreationQuery } from "./useSchoolsForChildCreationQuery";
import { useSearchInput } from "./useSearchInput";

type SchoolIdFromTreeProps = {
  /** Triggered whenever a school is selected */
  onSchoolSelect: (schoolId: School["id"]) => void;
  /** Triggered whenever a group is selected */
  onGroupSelect: (groupId: string) => void;
  /** Triggered whenever a group is auto-selected (there is only 1 group to choose from) */
  onGroupAutoSelect: (groupId: string) => void;
  /** Currently selected group */
  groupId: string | undefined;
  /** Email (to be integrated) */
  email?: string;
};

/**
 * Allows the user to select a school from a tree of schools based on the partner.
 * The currently selected group is passed via `groupId`.
 */
export function SchoolFromTree({
  onSchoolSelect,
  onGroupSelect,
  onGroupAutoSelect,
  groupId,
  email,
}: SchoolIdFromTreeProps) {
  const t = useT();
  const query = useSchoolsForChildCreationQuery(email);

  const allNodes = query.data ?? [];
  const nodesToDisplay = pickNodesToDisplay(allNodes, groupId);
  const selectedGroup = allNodes.find((node) => node.id === groupId);

  const { show, ref, nodes, setSearch, search } =
    useSearchInput(nodesToDisplay);

  // If there is only 1 group to choose from, auto-select it
  if (nodesToDisplay.length === 1 && !nodesToDisplay[0].isSchool) {
    onGroupAutoSelect(nodesToDisplay[0].id);
    return null;
  }

  return (
    <>
      <Heading>
        {selectedGroup ? selectedGroup.name : <T _str="Choose main location" />}
      </Heading>
      {query.isLoading ? (
        <div className="mt-8">
          <Spinner />
        </div>
      ) : (
        <>
          {show && (
            <Input.Search
              ref={ref}
              className="mb-3"
              placeholder={t("Search by name...")}
              value={search}
              onChange={setSearch}
            />
          )}
          {nodes.length ? (
            <ListSelect>
              {nodes.map((node) => (
                <ListSelect.Item
                  key={node.id}
                  label={node.name}
                  onClick={() => {
                    if (node.isSchool) {
                      onSchoolSelect(node.id);
                    } else {
                      onGroupSelect(node.id);
                    }
                  }}
                />
              ))}
            </ListSelect>
          ) : (
            <NoLocationsFound />
          )}
        </>
      )}
    </>
  );
}
