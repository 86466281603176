import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import classNames from "classnames";
import { useIntercom } from "react-use-intercom";

type Props = {
  /** Triggered after clicking the `Back` link. `Undefined` means there is no `back` action and the button should be hidden */
  onGoBack: (() => void) | undefined;
  className?: string;
};

export function GoBackFindHelpNavigation({ onGoBack, className }: Props) {
  return (
    <nav
      className={classNames(
        "flex items-center pt-[--status-bar-height]",
        className
      )}
    >
      {onGoBack && (
        <Button className="-ml-3" size="small" shape="plain" onClick={onGoBack}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-secondary mr-2"
          />
          <T _str="Back" />
        </Button>
      )}
      <HelpButton />
    </nav>
  );
}

/**
 * Remove fallbacks and modal once fully realeased
 */

const HelpButton = () => {
  const { show } = useIntercom();

  return (
    <Button
      onClick={() => show()}
      className="ml-auto -mr-3"
      size="small"
      shape="plain"
    >
      <T _str="Need help?" />
    </Button>
  );
};
