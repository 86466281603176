import { School } from "@kanpla/models";
import { T, useT } from "@kanpla/system";
import { Input, Spinner } from "@kanpla/ui";
import React from "react";
import { Heading } from "../../components/Layout";
import ListSelect from "../../components/ListSelect";
import { CanteenIdButton } from "../../components/WithCanteenId";
import { NoLocationsFound } from "./NoLocationsFound";
import { pickNodesToDisplay } from "./pickNodesToDisplay";
import { useSchoolsForChildCreationQuery } from "./useSchoolsForChildCreationQuery";
import { useSearchInput } from "./useSearchInput";

type SchoolIdFromTreeProps = {
  /** Triggered whenever a school is selected */
  onSchoolSelect: (schoolId: School["id"]) => void;
  /** Triggered whenever a group is selected */
  onGroupSelect: (groupId: string) => void;
  /** Triggered whenever a group is auto-selected (there is only 1 group to choose from) */
  onGroupAutoSelect: (groupId: string) => void;
  /** Currently selected group */
  groupId: string | undefined;
  /** Current user email */
  email?: string;
  showCanteenIdButton: boolean;
  onCanteenIdClick: () => void;
};

/**
 * Allows the user to select a school from a tree of schools based on the user logged in.
 * The currently selected group is passed via `groupId`.
 */
export function SchoolFromTreeExistingUser({
  onSchoolSelect,
  onGroupSelect,
  onGroupAutoSelect,
  groupId,
  email,
  showCanteenIdButton,
  onCanteenIdClick,
}: SchoolIdFromTreeProps) {
  const t = useT();
  const query = useSchoolsForChildCreationQuery(email, !!email);

  const allNodes = query.data ?? [];
  const nodesToDisplay = pickNodesToDisplay(allNodes, groupId);
  const selectedGroup = allNodes.find((node) => node.id === groupId);

  const { show, ref, nodes, setSearch, search } =
    useSearchInput(nodesToDisplay);

  // If there is only 1 group to choose from, auto-select it
  if (nodesToDisplay.length === 1 && !nodesToDisplay[0].isSchool) {
    onGroupAutoSelect(nodesToDisplay[0].id);
    return null;
  }

  const heading =
    showCanteenIdButton || !selectedGroup ? (
      <T _str="Add new location" />
    ) : (
      `${selectedGroup.name}:`
    );

  return (
    <>
      <Heading>{heading}</Heading>
      {showCanteenIdButton ? (
        <WithCanteenId navigate={onCanteenIdClick} />
      ) : null}
      {query.isLoading || !email ? (
        <div className="mt-8">
          <Spinner />
        </div>
      ) : (
        <>
          {show && (
            <Input.Search
              ref={ref}
              className="mb-3"
              placeholder={t("Search by name...")}
              value={search}
              onChange={setSearch}
            />
          )}
          {nodes.length ? (
            <ListSelect>
              {nodes.map((node) => {
                const label = `${node.name}${
                  node.disabled ? t(" (already added)") : ""
                }`;
                return (
                  <ListSelect.Item
                    key={node.id}
                    label={label}
                    disabled={node.disabled}
                    onClick={() => {
                      if (node.isSchool) {
                        onSchoolSelect(node.id);
                      } else {
                        onGroupSelect(node.id);
                      }
                    }}
                  />
                );
              })}
            </ListSelect>
          ) : (
            <NoLocationsFound />
          )}
        </>
      )}
    </>
  );
}
const WithCanteenId = ({ navigate }: { navigate: () => void }) => (
  <div className="flex flex-col gap-16 mt-8">
    <CanteenIdButton navigate={navigate}>
      <T
        _str="Add with {bold}"
        bold={
          <span className="font-semibold">
            <T _str="Canteen ID" />
          </span>
        }
      />
    </CanteenIdButton>
    <p className="title-secondary">
      <T _str="Or choose from a list:" />
    </p>
  </div>
);
